@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;

	@include section-padding;
}

.table_header {
	max-width: 1440px;
	margin: 0 auto;
	margin-bottom: 4rem;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	@include max-width-center;

	> header {
		display: flex;
		flex-direction: column;
		text-align: center;
	}

	@include xs-only {
		margin-bottom: 2rem;
	}
}

.header_title {
	@include xs-only {
		max-width: 250px;
		margin: 0 auto;
	}
}

.header_subtitle {
	color: $text-grey-dark;
}

.table_wrapper {
	position: relative;
	width: 100%;
	@include max-width-center;
}

.gradient_overlay {
	position: absolute;
	top: 0;
	bottom: -15px;
	border-radius: 6.528px;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #3e78f4 100%);
	pointer-events: none;
	z-index: 0;
	transform-origin: top;
	opacity: 0;
	transform: scaleY(0);
	transition: transform 1.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 1.5s cubic-bezier(0.4, 0, 0.2, 1);

	&.visible {
		opacity: 1;
		transform: scaleY(1);
	}
}

.table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	position: relative;

	td {
		padding: 1rem 0.75rem;
		text-align: center;
		border-bottom: 1px solid #d3d3d3;

		@include xs-only {
			padding: 0.75rem;
		}
	}

	th {
		text-align: center;
		font-weight: 400;
		padding: 1.25rem 1.5rem;
		padding-top: 2rem;
		vertical-align: baseline;
		border-bottom: 1px solid #d3d3d3;

		@include sm-down {
			padding: 1rem;
		}

		@include xs-only {
			padding: 0.75rem 0.5rem;
		}
	}

	tr td:first-child {
		text-align: left;
		font-weight: 400;

		@include xs-only {
			min-width: 80px;
			padding: 0.5rem;
			padding-left: 0;
		}
	}

	.checkmark {
		width: 1.5rem;
		height: 1.5rem;
		margin: 0 auto;
		position: relative;
		z-index: 1;
	}
}

.table_header_text {
	@include xs-only {
		font-size: 0.625rem;
		line-height: 0.875rem;
		letter-spacing: 0.0075rem;
	}
}

.table_body_text {
	@include xs-only {
		font-size: 0.8125rem;
		line-height: 0.975rem;
		letter-spacing: 0.00875rem;
	}
}
.gradient_column {
	position: relative;
}
