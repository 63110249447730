@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

$one-pixel-em: 0.0625em;
.checkbox {
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;

	cursor: pointer;

	width: 1em;
	height: 1em;

	border-radius: 0.25em;
	box-shadow: #a0a0a0 0 0 0 $one-pixel-em inset;

	&::before {
		content: '';
		position: absolute;
		width: 1em;
		height: 1em;
		border-radius: 0.25em;
		background-color: var(--checkbox-color);

		transform: scale3d(0, 0, 0);
		opacity: 0;
		transition: all 0.1s ease-out;
	}

	&:focus {
		outline: none;
		box-shadow: var(--checkbox-color) 0 0 0 $one-pixel-em inset;
	}

	&[data-checked='true'] {
		box-shadow: var(--checkbox-color) 0 0 0 $one-pixel-em inset;

		&:focus {
			box-shadow: var(--checkbox-color) 0 0 0 $one-pixel-em inset, var(--checkbox-color) 0 0 5px 2px;
		}

		&::before {
			transform: scale3d(0.75, 0.75, 1);
			opacity: 1;
			transition: all 0.1s ease-out;
		}
	}

	&[data-variant='circle'] {
		border-radius: 50%;

		&::before {
			border-radius: 50%;
		}
	}

	transition: all 0.1s ease-out;
}

.focus_target {
	width: 1em;
	height: 1em;
	border-radius: 0.25em;
	box-shadow: var(--checkbox-color) 0 0 0 $one-pixel-em inset, var(--checkbox-color) 0 0 0.25em 0.125em;

	opacity: 0;
	transition: opacity 0.1s ease-out;
}

.checkbox input:checked:focus + .focus_target {
	opacity: 0.4;
}
