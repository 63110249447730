@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

$slider-breakpoint: 1350px;

.container {
	background-color: $background-white;
	color: $text-black;

	@include section-padding;

	contain: paint;
}

.content {
	@include max-width-center;
}

.product_list {
	margin: 1.25rem 0 0;
	padding: 0;
	list-style: none;
	display: grid;
	grid-auto-flow: column;
	grid-template-rows: 1fr;
	grid-auto-columns: 1fr;
	gap: 1rem;

	@include sm-down {
		margin-top: 2rem;
		grid-auto-flow: row;
		grid-auto-rows: 1fr;
	}
}

.product_card {
	display: grid;
	grid-template-columns: 1fr 1fr;

	border-radius: $br-sm;
	contain: paint;

	background-color: $background-off-white;

	height: 100%;

	@include sm-down {
		grid-template-columns: 1fr;
	}
}

.product_card img {
	width: 100%;
	height: 100%;
	max-height: 217px;
	border-radius: $br-sm;

	@include sm-down {
		height: 230px;
		max-height: none;
	}
}

.product_card_content {
	padding: 30px 30px 20px;

	height: 100%;
	display: inline-flex;
	flex-direction: column;
	justify-content: space-between;

	@include sm-down {
		padding: 20px;
	}
}

.product_card_content header {
	p {
		color: $text-grey-dark;
	}

	margin-block-end: 1.5rem;

	@include sm-down {
		margin-block-end: 1.5rem;
	}
}

.slider_controls {
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-block: 1.25rem;

	@media (min-width: $slider-breakpoint) {
		display: none;
	}
}

.slider_buttons {
	display: flex;
	gap: 1rem;

	button {
		width: 2rem;
		height: 2rem;

		opacity: 1;

		&:disabled {
			opacity: 0.5;
		}
	}
}

.slider_dots {
	display: flex;
	gap: 0.5rem;

	button {
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 50%;

		opacity: 0.5;

		background-color: $background-black;

		&:disabled {
			opacity: 1;
		}
	}
}
